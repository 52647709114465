import 'core-js/stable';
import 'regenerator-runtime/runtime';

import bugsnag from '@bugsnag/js';

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' || process.env.RAILS_ENV === 'development' ? { logger: null } : {};

const bugsnagClient = bugsnag({
  apiKey: '2888ca3c23e58fa55db74f2fc09719a8',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['staging', 'production'],
  ...options,
});

if (window.App.rails.user) {
  const { id, email } = window.App.rails.user;
  bugsnagClient.user = { id, email };
} else if (window.App.rails.travel_helper) {
  const { id, email } = window.App.rails.travel_helper;
  bugsnagClient.user = { id, email };
} else if (window.App.rails.admin) {
  const { id, email } = window.App.rails.admin;
  bugsnagClient.user = { id, email };
}

window.bugsnagClient = bugsnagClient;
